import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { enableProdMode, ErrorHandler, APP_INITIALIZER, importProvidersFrom, Injector } from '@angular/core';
import {
  MatBottomSheet,
  MAT_BOTTOM_SHEET_DATA,
  MAT_BOTTOM_SHEET_DEFAULT_OPTIONS
} from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  PreloadAllModules,
  provideRouter,
  withInMemoryScrolling,
  withPreloading,
  withRouterConfig
} from '@angular/router';
import { provideLottieOptions } from 'ngx-lottie';

import { routes } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { UriConfig } from '@app/app.config';
import { ErrorHandlerInterceptor } from '@app/core/interseptors/error-handler.interceptor';
import { GlobalErrorHandler } from "@app/core/interseptors/global-error-handler.interceptor";
import { JwtTokenInterceptor } from '@app/core/interseptors/jwt-token.interceptor';
import { PlatformLoadingInterceptor } from '@app/core/interseptors/platform-loading.interceptor';
import { SetLocaleInterceptor } from '@app/core/interseptors/set-locale/set-locale.interceptor';
import { initializeAppFactory } from '@app/initializeAppFactory';
import { OnlyFrameAccessGuard } from '@guards/only-frame-access.guard';
import { AppInitializeService } from '@services/app-initialize.service';
import { SentryLoaderService } from "@services/sentry-loader.service";
import { SnackBarService } from '@services/snack-bar.service';

import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

(window as any).__errorBuffer = [];

window.onerror = (message, source, lineno, colno, error) => {
  (window as any).__errorBuffer.push(error);
};

window.onunhandledrejection = (event) => {
  (window as any).__errorBuffer.push(event.reason);
};

function lazyLoadSentry(injector: Injector) {
  const sentryLoader = injector.get(SentryLoaderService); // Получаем сервис через DI
  if ('requestIdleCallback' in window) {
    (window as any).requestIdleCallback(() => {
      sentryLoader.setUpSentry();
    });
  } else {
    setTimeout(() => {
      sentryLoader.setUpSentry();
    }, 3000);
  }
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule, MatDialogModule, MatSnackBarModule, MatButtonModule),
    UriConfig,
    SnackBarService,
    OnlyFrameAccessGuard,
    provideRouter(routes,
      withPreloading(PreloadAllModules),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      }),
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
        onSameUrlNavigation: 'reload'
      }),
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SetLocaleInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PlatformLoadingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    //uncomment if you need test error
    // { provide: HTTP_INTERCEPTORS,
    //   useClass: ErrorEmulationInterceptor,
    //   multi: true
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AppInitializeService],
      multi: true
    },
    { provide: MatBottomSheet },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} },
    { provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, useValue: { panelClass: 'frame-overlay-panel' } },
    provideLottieOptions({
      player: () => import('lottie-web'),
    }),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi())
  ]
}).then(appRef => {
  const injector = appRef.injector;
  lazyLoadSentry(injector);
})
  .catch(err => {
    console.error(err)
  });
