import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { TenantService } from "@common-services/tenant.services";


export const canMatchStreak = (): Observable<boolean> | Promise<boolean> | boolean => {
  const tenantService = inject(TenantService);
  const router = inject(Router);
  return combineLatest([
    tenantService.isStreak$,
    tenantService.isBracket$
  ]).pipe(
    take(1),
    map(([isStreak, isBracket]) => {
      if (!isBracket && !isStreak) {
        router.navigateByUrl('/frame/landing');
        return false;
      }

      if (isBracket) {
        router.navigateByUrl('/frame/bracket');
        return false;
      }
      return true;
    })
  );
};

export const canMatchBracket = (): Observable<boolean> | Promise<boolean> | boolean => {
  const tenantService = inject(TenantService);
  const router = inject(Router);
  return combineLatest([
    tenantService.isStreak$,
    tenantService.isBracket$
  ]).pipe(
    take(1),
    map(([isStreak, isBracket]) => {
      if (!isBracket && !isStreak) {
        router.navigateByUrl('/frame/landing');
        return false;
      }

      if (isStreak) {
        router.navigateByUrl('/frame/streak');
        return false;
      }
      return true;
    })
  );
};

export const canMatchPrediction = (): Observable<boolean> | Promise<boolean> | boolean => {
  const tenantService = inject(TenantService);
  const router = inject(Router);
  return combineLatest([
    tenantService.isStreak$,
    tenantService.isBracket$
  ]).pipe(
    take(1),
    map(([isStreak, isBracket]) => {
      if (isStreak) {
        router.navigateByUrl('/frame/streak');
        return false;
      }

      if (isBracket) {
        router.navigateByUrl('/frame/bracket');
        return false;
      }
      return true;
    })
  );
};
