import { AsyncPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { interval, Subject } from "rxjs";
import { combineLatest } from "rxjs";
import { filter, switchMap, takeUntil } from "rxjs/operators";

import { AuthService } from "@services/auth.service";
import { GameService } from "@services/game.service";
import { LoaderControllerService } from "@services/loader-controller.service";
import { LocalStorageService } from "@services/local-storage.service";
import { MaintenanceControlService } from "@services/maintenance-control.service";
import { PlatformDetectorService } from "@services/platform-detector.service";
import { UserService } from "@services/user.service";


@Component({
    selector: 'hun-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, AsyncPipe]
})
export class AppComponent implements OnInit, OnDestroy {

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private gameService: GameService,
    private platformDetectorService: PlatformDetectorService,
    public loaderControllerService: LoaderControllerService,
    private userService: UserService,
    private maintenanceControlService: MaintenanceControlService,
  ) {
  }
  ngOnInit(): void {
    this.userDataUpdate();
    this.loaderControllerService.isLoading$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(isLoading => {
        const initialLoader = document.getElementById('initial-loader');
        if (initialLoader) {
          initialLoader.style.display = isLoading ? 'flex' : 'none';
        }
      });

    this.platformDetectorService.isSafari$
      .pipe(
        filter(isSafari => isSafari),
        takeUntil(this.unsubscribe$))
      .subscribe(() => document.body.classList.add('safari-browser'))
    this.authService.processIsRestrictedUser();
    this.maintenanceControlService.maintenanceControl();
    this.localStorageService.removeDefaultUserTokenToken();
    // if (!this.gameService.isPreviewGame$.value) {
    //   this.localStorageService.deletePreviewAnswers();
    // }

    this.authService.enableLogoutAfterClose$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        if (value) {
          window.addEventListener('beforeunload', (e) => {
            delete (window as any).sentryInstance;
            window.opener?.postMessage(`childWindowClosed_${this.userService.userDetails$.value?.id}`, '*');
          })
        }
    })
  }

  userDataUpdate() {
    interval(120000)
      .pipe(
        switchMap(() => combineLatest([
          this.authService.isAuthFlow$,
          this.maintenanceControlService.isMaintenance$
        ])),
        filter(([isAuthFlow, isMaintenance]) => !isAuthFlow && !isMaintenance),
        switchMap(() => this.authService.userDataUpdate()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }


  ngOnDestroy() {
    this.maintenanceControlService.destroy();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
