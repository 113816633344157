import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { UriConfig } from "@app/app.config";
import { TenantService } from "@common-services/tenant.services";
import { CmsContentService } from "@services/cms-content.service";
import { GameService } from "@services/game.service";
import { SnackBarService } from "@services/snack-bar.service";


export enum CustomMessagesEndpointsEnum {
	['/answers/submit'] = "iframe-v3-round-close-BE-error",
	['/auth/refresh'] = "iframe-v3-auth-refresh-BE-error"
}

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

	errorCode = [];

	constructor(private snackBarService: SnackBarService,
							private cmsContentService: CmsContentService,
							private tenantService: TenantService,
							private router: Router,
							private uriConfig: UriConfig,
							private gameService: GameService
	) {
		this.errorCode = Object.values(this.uriConfig)
			.map(value => {
				return this.getFirstPathSegment(value)
			})
			.filter(value => value)
			.sort();
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				if ((this.tenantService.isSuperbetPromo$.value || window.location.href.includes('test')) && error.status === 401) {
					this.router.navigate(['auth/sign-in']);
				} else if (error.error?.path?.includes('/answers/submit')) {
					let targetUrl = 'frame/landing';

          if (this.tenantService.isStreak$.value) {
            targetUrl = 'frame/streak/home';
          } else if (this.tenantService.isBracket$.value){
            targetUrl = 'frame/bracket/landing';
          }

					this.gameService.reloadRound();
					this.router.navigate([targetUrl]);
				} else if (error.status !== 403) {
					const errorUrl = this.getFirstPathSegment(request.url);
					const errorCode = this.errorCode.indexOf(errorUrl);
					let errorMessage = this.getErrorMessage(error, errorCode);
					this.showErrorMessage(errorMessage, error, errorCode);
				}

				return throwError(error);
			})
		);
	}

	private getErrorMessage(error: HttpErrorResponse, errorCode: number): string {
		if (error.status === 500) {
			return `Server error type ${errorCode}`;
		} else if (CustomMessagesEndpointsEnum[error.error?.path] && this.cmsContentService.cmsContent$.value['text_content'][CustomMessagesEndpointsEnum[error.error?.path]]) {
			return this.cmsContentService.cmsContent$.value['text_content'][CustomMessagesEndpointsEnum[error.error?.path]];
		} else {
			return this.extractErrorMessage(error.error, errorCode);
		}
	}

	private extractErrorMessage(error: any, errorCode: number): string {
		let errorMessage = `An error type ${errorCode} occurred. Please try again later.`;
		if (error) {
			if (typeof error === 'string') {
				errorMessage = error;
			} else if (error.message) {
				if (typeof error.message === 'string') {
					errorMessage = error.message;
				} else if (Array.isArray(error.message)) {
					errorMessage = error.message.join(', ');
				}
			}
		}
		return errorMessage;
	}

	private showErrorMessage(errorMessage: string, error: HttpErrorResponse, errorCode: number): void {

		if (errorMessage) {
			this.snackBarService.showSnackBar(errorMessage);
			console.error(errorMessage);
		} else {
			console.error(`Unknown error type ${errorCode} occurred:`, error);
		}
	}

	getFirstPathSegment(url: string): string | null {
		try {
			const relativePath = url.replace(this.uriConfig.baseApiUrl, "");
			const segments = relativePath.split("/").filter(Boolean);

			return segments.length > 0 ? segments[0] : null;
		} catch (error) {
			return null;
		}
	}
}
