import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable, of, switchMap, take } from "rxjs";
import { catchError, tap } from "rxjs/operators";

import { AuthValidationService_v3 } from "@common-services/auth-validation-service_v3.service";
import { User } from "@models/User";
import { AuthService } from "@services/auth.service";
import { LocalStorageService } from "@services/local-storage.service";
import { MaintenanceControlService } from "@services/maintenance-control.service";


@Injectable({
  providedIn: 'root'
})
export class OnlyFrameAccessGuard  {

  constructor(
    private authService: AuthService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private maintenanceControlService: MaintenanceControlService,
    private authValidationService: AuthValidationService_v3
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.maintenanceControlService.isMaintenance$.pipe(
      take(1),
      switchMap((isMaintenance) => {
        this.authService.isAuthFlow$.next(false);

        if (isMaintenance) {
          return of(true);
        }

        if (state.url.includes('#') || state.url.includes('+') || state.url.includes('%')) {
            const queryParams = this.extractQueryParams(state.url);
            route.queryParams = { ...route.queryParams, ...queryParams };
        }

        if (route.queryParams['accessToken'] && route.queryParams['uniqueKey'] && route.queryParams['userToken']) {
          this.localStorageService.setDefaultUserTokenToken(route.queryParams['userToken']);
          this.authService.processCurrentUser();
          this.authValidationService.defaultUserAvailability$.next(true);
          return of(true);
        }

        if (route.queryParams['userToken'] && !route.queryParams['accessToken'] && !route.queryParams['uniqueKey']) {
          this.localStorageService.setDefaultUserTokenToken(route.queryParams['userToken']);
          this.authService.enableLogoutAfterClose$.next(true);
          this.authService.isPreviewUsesOnlyUserToken$.next(true);
          this.authService.processCurrentUser();
          return this.authService.processFrameIntegration(route)
        }

        if (route.queryParams['login'] && route.queryParams['password']) {
          const newUser = {
            ...new User,
            password: route.queryParams['password'],
            email: route.queryParams['login'].replace(' ', '+')
          }

          return this.authService.userSignIn(newUser)
            .pipe(
              tap(() => this.maintenanceControlService.closeConnection()),
              switchMap(() => {
                this.maintenanceControlService.subscribeMaintenanceCheck();
                return this.authService.processFrameIntegration(route);
              }),
              catchError(error => {
                return this.authService.processFrameIntegration(route)
              })
            )
        }
        if (this.router.url.includes('lock')){
          return  of(true);
        }

        if (
          state.url.includes('/frame') ||
          state.url.includes('/preview')
        ) return of(true);
        return this.authService.processFrameIntegration(route)
      }),
      map(value => value),
    )
  }
  extractQueryParams(url: string) {
    const queryString = url.split('?')[1];

    return queryString.split('&').reduce((acc, keyValue) => {
      const [key, ...valueParts] = keyValue.split('=');
      let value = valueParts.join('=');
      value = value.includes('@') ? value.replace(/%20/g, '+') : value;
      acc[key] = value;
      return acc;
    }, {});
  }
}
