import { Routes } from "@angular/router";

import { LockComponent } from "@components/lock/lock.component";
import { AllowAuthNextStep } from "@guards/allow-auth-next-step.guard";
import { AllowManualSignInGuard } from "@guards/allow-manual-sign-in.guard";
import { FrameAccessGuard } from "@guards/frame-access.guard";
import { OnlyFrameAccessGuard } from '@guards/only-frame-access.guard';
import { canMatchBracket, canMatchPrediction, canMatchStreak } from "@guards/tenants.guard";

import { PreviewComponent } from "./preview/preview.component";

export const routes: Routes = [
  {
    path: 'restorePassword',
    canActivate: [AllowManualSignInGuard],
    canMatch: [AllowAuthNextStep],
    loadChildren: () => import('./app-common/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'auth',
    canActivate: [AllowManualSignInGuard],
    loadChildren: () => import('./app-common/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path:'',
    canActivate: [OnlyFrameAccessGuard],
    children: [

      {
        path: 'lock',
        component: LockComponent
      },
      {
        path: 'preview',
        component: PreviewComponent
      },
      {
        path: 'frame',
        canActivate: [FrameAccessGuard],
        children: [
          {
            path: 'streak',
            canMatch: [canMatchStreak],
            loadChildren: () => import('./frame-streak/frame-streak-routing.module')
          },
          {
            path: 'bracket',
            canMatch: [canMatchBracket],
            loadChildren: () => import('./frame-bracket/frame-bracket-routing.module')
          },
          {
            path: '',
            canMatch: [canMatchPrediction],
            loadChildren: () => import('./frame-v3/frame-v3-routing.module')
          },
        ]
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'frame/landing'
  },
];
