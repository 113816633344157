import { ErrorHandler, Injectable } from '@angular/core';
import { createErrorHandler } from "@sentry/angular";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private sentryHandler = createErrorHandler({ showDialog: false });

  handleError(error: any): void {
    this.sentryHandler.handleError(error);

    if (!(window as any).sentryInstance) {
      (window as any).__errorBuffer.push(error);
    }
  }
}
